(function ( $ ) {
    $.fn.fullScreenMenu = function( options ) {
        // オプション設定（属性: 値）
        var defaults = {
			buttonId: "fullScreenMenuButton",
			breakpoint: "",
        };
        var settings = $.extend( {}, defaults, options );
		var that = this;

        return this.each(function() {
            // メイン処理

			//ボタンを生成
			$("body").append('<div id="'+settings.buttonId+'"><div id="hamburger"><span class="top"></span> <span class="middle"></span> <span class="bottom"></span></div></div>');

            // var breakpoint_flag = settings.breakpoint ? settings.breakpoint >= $(window).width() : true;

			// メニューを開く
			$('#'+settings.buttonId).add('nav a').on('click', function(){
				if(settings.breakpoint >= $(window).width() || settings.breakpoint == "") {
					// var menuOpenFlag = $("body").hasClass("is-close");
					if(!($("body").hasClass("is-open"))) {
                        $(that).fadeIn();
						$("body").removeClass("is-close").addClass("is-open");
						// 現在のスクロール位置を取得
						$('html').data('scrTop', $(window).scrollTop());//メニューを開いた位置を保存
						$('body,html').css({"position":"fixed","top": -1 * $('html').data('scrTop')});
					} else {
						$("body").removeClass("is-open").addClass("is-close");
						$('body,html').css({"position":"static","overflow":"visible","height":"auto"});
						// メニューを開いた時点のスクロール位置を反映
						$(window).scrollTop($('html').data('scrTop'));//メニューを開いた位置へ移動
						$(that).fadeOut();
					}
				}
			});

			// $(window).on('resize', function(){
			// 	if(settings.breakpoint >= $(window).width() || settings.breakpoint == "") {
            //         if(settings.breakpoint == "" && $("body").hasClass("is-open")) {
            //             $(that).show();
            //         } else {
            //             $(that).hide();
            //         }
			// 	} else {
			// 		$(that).show();
			// 	}
			// }).resize();

        });
    };
}( jQuery ));
