// const video = document.querySelector(".js-video-player-content");
// video.play();


let videoElem = document.querySelector(".js-video-player-content");
playVideo();
async function playVideo() {
  try {
    await videoElem.play();
  } catch(err) {
    console.log(err);
  }
}

// class VideoPlayer {
//   constructor() {
//     this.targets = document.querySelectorAll('.js-video-player')
//   }
  
//   init() {
//     this.fixed();
//     this.attachEvent();
//   }

//   fixed() {
//     for(const target of this.targets) {
//       let video = target.querySelector('.js-video-player-content')
//       let width = target.clientWidth
//       let height = target.clientHeight
//       let ratio = target.getAttribute('data-ratio').split(':')
//       let flag = width * ratio[1] / ratio[0] < height
//       let useSize = flag ? height : width
//       let useRatio = flag ? ratio[0] / ratio[1] : ratio[1] / ratio[0]
//       let videoWidth = video.clientWidth
//       let videoHeight = video.clientHeight

//       let style = new Map([
//         ['position', 'absolute'],
//         ['width', flag ? `${useSize * useRatio}px` : `${useSize}px`],
//         ['height', flag ? `${useSize}px` : `${useSize * useRatio}px`],
//         ['left', flag ? `${-(videoWidth - width) / 2}px` : 0],
//         ['top', flag ? 0 : `${-(videoHeight - height) / 2}px`]
//       ])
      
//       for (const [key, value] of style) {
//         video.style[key] = value;
//       }

//       // this.hideCover(video);
//       this.videoLoaded(video, target);

//     }
//   }
  
//   hideCover(video) {
//     video.addEventListener('play', () => {
//       $(".top-hero-videoCover").hide();
//     });
//   }
  
//   videoLoaded(video) {
//     video.addEventListener('play', () => {
//       $(".top-bgMovie").addClass('is-loaded');
//       // target.classList.add('is-loaded');
//       this.fixed();
//     });
//     video.addEventListener('canplaythrough', () => {
//       $(".top-bgMovie").addClass('is-loaded');
//       // target.classList.add('is-loaded');
//       this.fixed();
//     });
//   }
  
//   attachEvent() {
//     window.addEventListener('resize', () => {
//       this.fixed();
//       $(".top-bgMovie").addClass('is-loaded');
//     })
//   }

// }

// const videoPlayer = new VideoPlayer();
// videoPlayer.init();

// $(window).on('load', function() {
//   $(".top-bgMovie").addClass('is-loaded');
// })
