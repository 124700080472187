/*

    v1.1.0
       _                                                           _                _
      (_) __ _ _   _  ___ _ __ _   _   _ __ ___   __ _ _ __  _ __ (_)_ __   __ _   (_)___
      | |/ _` | | | |/ _ \ '__| | | | | '_ ` _ \ / _` | '_ \| '_ \| | '_ \ / _` |  | / __|
      | | (_| | |_| |  __/ |  | |_| |_| | | | | | (_| | |_) | |_) | | | | | (_| |_ | \__ \
     _/ |\__, |\__,_|\___|_|   \__, (_)_| |_| |_|\__,_| .__/| .__/|_|_| |_|\__, (_)/ |___/
    |__/    |_|                |___/                  |_|   |_|            |___/ |__/

*/
(function($){

  $.fn.mapping = function(options) {
    var map;

    var settings = $.extend({
        center: {},
        zoom: 14,
        markers: [],
        autoZoomAftertBounds: false,
    }, options);

    var methods = {
        getmap: function () {
            return map;
        },
        create_marker: function (location, data) {
            return setMarker(location, data);
        },
        remove_marker: function (marker) {
            if(! marker) {
                return;
            }
            marker.setMap(null);
        },
        fitbounds: function (map, markers) {
            var lats = $.map(markers, function (v) { return v.position.lat() });
            var lngs = $.map(markers, function (v) { return v.position.lng() });

            var nw = new google.maps.LatLng(Math.min.apply(null, lats), Math.min.apply(null, lngs));
            var se = new google.maps.LatLng(Math.max.apply(null, lats), Math.max.apply(null, lngs));

            if(! nw || ! se) {
                return;
            }
            map.fitBounds(new google.maps.LatLngBounds(nw, se));
        }
    };

    $.fn.mapping = function (method) {
        if(methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        }
        else if(typeof method === 'object' || ! method){
            return methods.init.apply(this, arguments);
        }
        else {
            $.error('Method ' +  method + ' does not exist on jQuery.tooltip');
        }
    };

    var setMarker = function(position, data){
        var data = data || {};

        var options = {
            position: position,
            map: map,
            icon: data.icon || ''
        }

        if(data.dragend) {
            $.extend(options, {draggable: true});
        }

        var marker;
        var dfd = $.Deferred();
        var img;
        if(data.icon) {
            img = new Image();
            img.src = data.icon;
            img.onload = function () {
                marker = new google.maps.Marker({
                    icon: {
                        url: data.icon,
                        scaledSize: new google.maps.Size(img.width / 2, img.height / 2)
                    },
                    position: position,
                    map: map,
                    center: 'point'
                });
                map.panBy(0,  - (img.height / 4));
                return dfd.resolve();
            }
        }
        else{
            marker = new google.maps.Marker(options);
            dfd.resolve();
        }

        dfd.then(function (e) {
            if(data.dragend) {
                google.maps.event.addListener(marker, 'dragend', function (e){
                    data.dragend(e);
                });
            }

            var balloon = new google.maps.InfoWindow({position: position, content: data.title /* content: n + '. ' + data.name,*/});

            if(data.click) {
                google.maps.event.addListener(marker, 'click', function (e){
                    if($(map).data('balloon')){
                        $(map).data('balloon').close();
                    }
                    balloon.open(marker.getMap(), marker);

                    $(map).data('balloon', balloon);
                });
            }

            google.maps.event.addDomListener(window, 'resize', function () {
                if(data.center == 'icon') {
                    map.panBy(0,  - (img.height / 4));
                }
                map.panTo(position);
        }   );

            if(data.init) {
                data.init(marker);
            }
        })

        return marker;
    }

    return this.each(function() {
        var position = new google.maps.LatLng(settings.center.lat, settings.center.lng);
        var mapOptions = {
            zoom: settings.zoom,
            center: position,
            scrollwheel: false
        }
        map = new google.maps.Map(this, $.extend(mapOptions, settings.mapOptions, true));

        if(! $.isEmptyObject(settings.markers)) {
            var lats = [], lngs = [];

            var markers = [];
            $.each(settings.markers, function (i, v) {
                markers.push(setMarker(new google.maps.LatLng(v.lat, v.lng), v));
            });


            if(! settings.autoZoomAftertBounds) {
                google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
                    map.setZoom(settings.zoom);
                });
            }
            else{
                $(this).mapping('fitbounds', map, markers);
            }
        }

    });

  };
})(jQuery);